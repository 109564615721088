import React, {FunctionComponent,useState, MouseEvent, useEffect } from "react"
import { withPrefix } from "gatsby"
import styled from 'styled-components'

const emptySessions = [
    "networking",
    "lunch"
]
const CalendarBar = styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #fff;
        margin-top: 0px;
        z-index: 99999;
        margin-bottom: 0px;

        .titlehead{
          max-width: 1400px;
          margin: 0 auto;
          background-color: #fff;
          color: #000;
          margin-top: 0px;
          margin-bottom: 0px;
          padding-top: 60px;
          font-family: 'RedHatDisplay-Black',sans-serif;
          font-size: 62px;
          letter-spacing: 0.6px;
          span{
            color: #8733ff;
          }

        }
        .tabs {
            max-width: 1400px;
            margin: 0 auto;
            font-family: 'Metropolis-SemiBold',sans-serif;
            font-size: 30px;
            font-weight: 300;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 20px 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            padding-top: 50px;
            padding-bottom: 50px;
            margin-bottom: 50px;
        }
        .tabs div.sessions {
            grid-column-start: 1;
            grid-column-end: 7;
            margin-top: 70px;

          }




          .tabs div.header {
            background-color: #fff;
            color: #8733ff;
            grid-row-start: 1;
            height: 62px;
            cursor: pointer;



            border: 1px solid #000;

            margin-right: 0px;
            margin-left: 0px;
            .monthday{
                padding-left: 15px;
                padding-top: 5px;
                padding-bottom: 5px;
            }
          }
          .tabs div.header:hover,div.header.active:hover{
           .barActive{
               display: block;
               background-color: #8733ff;
               height: 15px;
           }
          }



          .header.active{
            background-color: #fff !important;
            .barActive{
                display: block;
                border-top: 1px solid #000;
                background-color: #8733ff;
                width: 100%;
                height: 15px;
            }

          }

          .sessions .session:last-of-type .linedivider{
            border: none;
          }

          .barActive{
              display: block;
              background-color: #fff;
              border-top: 1px solid #000;

          }

          .videoBlock {
              width: 100%;
              background-color: #fff;
              margin-bottom: 50px;
              .videoPlayer{
                  max-width: 800px;
                  width: 100%;
                  height: 420px;
                  background-color: gray;
                  margin: 0 auto;
              }
          }

          .videoBlock.hidden {
            display: none;
          }

          .brick{
              background-color: #000;
          }
          .fulldate{
            font-family: 'Metropolis-ExtraBold',sans-serif;
            font-size: 30px;
            color: #fff;
            margin: 0 auto;

            background-color: #000;
            padding-bottom: 40px;
            padding-top: 40px;
            max-width: 1400px;
          }
          .brick{

          }
          .sessionWrap{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            .blocks {
                border: 1px solid #000;
                a{
                    text-decoration: none;
                    color: #fff;
                }
                img{
                    width: 100%;
                    height: 100%;
                    filter: brightness(0.30);
                }
                .vidTitle{
                    position: relative;
                    bottom: 35px;
                    text-align: center;
                    width: 100%;
                    border: none;
                    /*margin-bottom: -23px;*/
                }
            }
            .blocks.on-demand {
              img {
                filter: none !important;
              }
            }
        }

          @media only screen and (min-width: 1441px) and (max-width: 1600px){
            .tabs,.titlehead{
                max-width: 1200px;
            }
          }

          @media only screen and (min-width: 1200px) and (max-width: 1441px){
            .tabs,.titlehead,.fulldate{
                max-width: 960px;
            }

          }
          @media only screen and (max-width: 1440px) and (min-width: 1200px){
            .tabs,.titlehead,.fulldate{
              max-width: 960px;
            }
          }
          @media only screen and (max-width: 1199px) and (min-width: 767px){
            .tabs,.titlehead,.fulldate{
              max-width: 680px;
            }
            .tabs {
              grid-template-columns: 1fr 1fr 1fr;
              grid-template-rows: 63px 1fr;
            }

            .tabs div.header {
              grid-row-start: 1;
              margin-left: 0px;
              margin-right: 0px;
              border-radius: 0px;
              border-top: 1px solid #000;
            }
            .tabs div.header.item2, .tabs div.header.item3, .tabs div.header.item4{
              grid-row-start: 2;
              margin-top: 20px;
            }
            .tabs div.sessions {
              grid-column-start: 1;
              /*grid-column-end: 7;*/
            }
            .speakerOuterWrap{
              grid-template-columns: 1fr;
            }
            .active .caretUp{
              display: block;
              float: right;
              margin-right: 20px;
              margin-top: 10px;
            }
            .caretDown{
              display: block;
              float: right;
              margin-right: 20px;
              margin-top: 10px;
            }
            .active .caretDown{
              display: none;
            }

          }

          @media only screen and (max-width: 768px) {
            .titlehead{
              max-width: 95%;
              .hideDash{
                display: none;
              }
            }
            .tabs,.fulldate{
              max-width: 95%;
              font-size: 18px;
            }
            .tabs {
              grid-template-columns: 1fr 1fr 1fr;
              grid-template-rows: 63px 1fr;
            }

            .tabs div.header {
              grid-row-start: 1;
              margin-left: 0px;
              margin-right: 0px;
              border-radius: 0px;
              border-top: 1px solid #000;
              height: 48px;
            }
            .tabs div.header.item2, .tabs div.header.item3, .tabs div.header.item4{
              grid-row-start: 2;
              margin-top: 20px;
            }
            .tabs div.sessions {
              grid-column-start: 1;
              /*grid-column-end: 7;*/

            }
            div.session{
              grid-template-columns: 1fr;

            }

          }
    `

const MultiDayOnDemand = props => {
    const [activeTab, updateActiveTab] = useState(props.activeTab || 0)
    const dates = props.sessions && props.sessions.filter(({ type, date }) => !emptySessions.includes(type) && date)
        .map(({ date }) => date)
    return <CalendarBar>
        <h1 className="titlehead">On Demand <span className="hideDash">-</span> <span>Watch Now</span></h1>
        <section className="tabs">
        <div className={`header ${(activeTab==="fullweek") ? "active" : ""}`} onClick={() => updateActiveTab("fullweek")}>
            <div className="monthday">Full Week</div>
            <div className="barActive"></div>
        </div>

        {dates && [...new Set(dates)].map((currentDate, index) => {
            // Parse dates according to design
            let suffix = 'th'
            let dateObject = new Date(currentDate)
            let dateModulos = dateObject.getDate() % 10

            suffix = dateModulos === 0 ? 'th' : suffix
            suffix = dateModulos === 1 ? 'st' : suffix
            suffix = dateModulos === 2 ? 'nd' : suffix
            suffix = dateModulos === 3 ? 'rd' : suffix
            let [weekday, month, day] = dateObject.toLocaleDateString(undefined, { weekday: 'long', month: 'short', day: 'numeric' }).split(' ')

            // 1. Filter sessions by dates
            // 2. Render div.header for each date
            // 3. If date is active, render div.sessions
            // with <MultiDaySession> for each
            let sessions = props.sessions.filter(({ date }) => date === currentDate)
            // TODO: Implement better performance


            return (<React.Fragment key={index} >



                <div className={`header ${(activeTab === index) ? "active" : ""} item${index}`} onClick={() => updateActiveTab(index)}>

                    <div className="monthday">Day {`${index+1}`}</div>
                    <div className="barActive"></div>
                </div>



            </React.Fragment>)
        }
        )}
    </section>

    <div className={`videoBlock ${props.hideVideoPlayer&&'hidden'}`}>
      <div className="videoPlayer">{props.children}</div>
    </div>

    {dates && [...new Set(dates)].map((currentDate, index) => {
            // Parse dates according to design
            let suffix = 'th'
            let dateObject = new Date(currentDate)
            let dateModulos = dateObject.getDate() % 10

            suffix = dateModulos === 0 ? 'th' : suffix
            suffix = dateModulos === 1 ? 'st' : suffix
            suffix = dateModulos === 2 ? 'nd' : suffix
            suffix = dateModulos === 3 ? 'rd' : suffix
            let [weekday, month, day] = dateObject.toLocaleDateString(undefined, { weekday: 'long', month: 'short', day: 'numeric' }).split(' ')

            // 1. Filter sessions by dates
            // 2. Render div.header for each date
            // 3. If date is active, render div.sessions
            // with <MultiDaySession> for each
            let sessions = props.sessions.filter(({ date }) => date === currentDate)
            // TODO: Implement better performance


            return (<React.Fragment key={index} >





                {(activeTab === index) && sessions && <div className="brick">
                    <div className="fulldate">Day {index+1}: November {day}, 2020</div>
                    <div className="sessionWrap">
                    {sessions.map((session, key) => {

                        return <MultiDayOnDemandSessions key={key} {...session} onClick={props.onSessionClick} ondemandVideoIds={props.ondemandVideoIds}/>
                    })}
                    </div>
                </div>}
                {(activeTab === "fullweek") && sessions && <div className="brick">
                    <div className="fulldate">Day {index+1}: November {day}, 2020</div>
                    <div className="sessionWrap">
                    {sessions.map((session, key) => {

                        return <MultiDayOnDemandSessions key={key} {...session} onClick={props.onSessionClick} ondemandVideoIds={props.ondemandVideoIds} />
                    })}
                    </div>
                </div>}

            </React.Fragment>)
        }
        )}
    </CalendarBar>
}

const MultiDayOnDemandSessions = (props) => {
  const [hasOnDemandVideo, setOnDemandVideo] = useState(false)

  useEffect( () => {
    props.ondemandVideoIds && props.videoId && setOnDemandVideo(props.ondemandVideoIds.includes(props.videoId))
  }, [props.ondemandVideoIds, props.videoId])

  const handleOnClick = (event:MouseEvent) => {
    event.preventDefault()
    hasOnDemandVideo && props.onClick && props.onClick(props.videoId)
  }

  return (
    <div className={`blocks ${hasOnDemandVideo&&'on-demand'}`}>
      <a href="#" target="_blank" onClick={handleOnClick}>
        <img src={withPrefix(props.image)} alt={props.title} />
        <div className="vidTitle">{props.title}</div>
      </a>
    </div>
  );
};


export default MultiDayOnDemand
