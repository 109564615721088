import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "gatsby";
import MultiDayOnDemand from "../../components/multidayondemand";
import styled from "styled-components";

const OnDemandBar = styled.div`
  background-color: #000;
  color: #fff;

  h1.titlecheck {
    font-family: "Metropolis-SemiBold", sans-serif;
    font-size: 124px;
    letter-spacing: 2px;
    line-height: 1.1;
    margin-left: 115px;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .sessionWrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .blocks {
      border: 1px solid #000;
      a {
        text-decoration: none;
        color: #fff;
      }
      img {
        width: 100%;
      }
      .vidTitle {
        position: relative;
        bottom: 35px;
        text-align: center;
        width: 100%;
        border: none;
        margin-bottom: -23px;
        font-family: "Metropolis", sans-serif;
        text-transform: uppercase;
        letter-spacing: 1px;
        @media only screen and (min-width: 1440px) {
          font-size: 20px;
        }
      }
    }
  }

  .videoWrap {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 0px;
    background-color: #fff;
    color: #000;
    .container {
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      grid-column-gap: 90px;
      margin: 0 auto;
      margin-left: 115px;
      margin-right: 125px;
      max-width: 1200px;
      .DateBanner {
        text-align: center;
        font-size: 36px;
        margin-top: 10px;
        .daynum {
          font-family: "Metropolis-SemiBold", sans-serif;
        }
        .divbreak {
          color: #ccc;
        }
        .specdate {
        }
        .imageDesk {
          margin-top: 30px;
          width: 100%;
        }
        .imageMobile {
          display: none;
        }
      }
      .video {
        img {
          width: 100%;
        }
      }
    }
  }
  .toggleAgenda {
    background-color: #8733ff;
    color: #000;
    font-size: 20px;
    text-align: right;
    .container {
      max-width: 980px;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 20px;
      button {
        height: 35px;
        width: 200px;
        color: #8733ff;
        text-align: center;
        margin-left: 85px;
        font-family: "Metropolis-SemiBold", sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-radius: 3px;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    .DateBanner {
      font-size: 30px !important;
    }
    .toggleAgenda {
      .container {
        max-width: 680px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    h1.titlecheck {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      margin-top: 30px;
      font-size: 40px;
      letter-spacing: 0.5px;
    }
    .toggleAgenda {
      .container {
        max-width: 95%;
        text-align: center;
        button {
          margin: 0 auto;
          margin-top: 30px;
        }
      }
    }
    .sessionWrap {
      grid-template-columns: 1fr 1fr;
    }
    .videoWrap {
      padding-bottom: 50px;
      padding-top: 50px;
      .container {
        grid-template-columns: 1fr;
        margin: 0 auto;
        width: 95%;
        .DateBanner {
          margin-left: 30px;
          margin-right: 30px;
          .imageDesk {
            display: none;
          }
          .imageMobile {
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`;

const OnDemand = ({
  sessions,
  activeTab = null,
  onSessionClick,
  children,
  hideVideoPlayer,
  ondemandVideoIds,
}) => {
  return (
    <OnDemandBar>
      <MultiDayOnDemand
        sessions={sessions}
        activeTab={activeTab}
        onSessionClick={onSessionClick}
        hideVideoPlayer={hideVideoPlayer}
        ondemandVideoIds={ondemandVideoIds}
      >
        {children}
      </MultiDayOnDemand>
    </OnDemandBar>
  );
};

export default OnDemand;
